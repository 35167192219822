<template>
  <BButton
    :id="`seat-${seat.row}-${seat.col}`"
    class="btn-icon my-50 p-0"
    :style="
      ['xs', 'sm', 'md'].includes(appBreakPoint)
        ? `width:25px;height:30px`
        : `height:40px; width:40px;`
    "
    :variant="setVariantSeat(seat)"
    :disabled="setDisabledSeat(seat)"
    :class="setClassSeat(seat)"
    @click="() => $emit('handleClickSeat', seat)"
  >
    <span :class="`text-nowrap ${['xs','sm','md'].includes(appBreakPoint) ? ' font-small-1' : ' font-small-3'}`">
      {{ seat.row }}{{ seat.col }}
    </span>

    <b-tooltip
      :id="`tooltip-${seat.row}-${seat.col}`"
      :target="`seat-${seat.row}-${seat.col}`"
      triggers="hover"
      variant="warning"
      placement="top"
      boundary="window"
      :disabled="!seat.isAvailable"
    >
      <div class="px-1 py-50">
        <h5
          class="text-white pb-25 d-flex justify-content-between border-bottom text-nowrap"
        >
          <span
            class="mr-2 text-dark"
          >{{ $t('flight.seatNumber') }} {{ seat.row }}{{ seat.col }}</span>
        </h5>

        <div class="text-left text-dark">
          {{ $t('flight.seatDescription') }}:
          <div v-if="!isEmpty(resolveLocationBySeat(seat, source))">
            <p
              v-for="(location, index) of resolveLocationBySeat(seat, source)"
              :key="index"
              class="mb-25 text-dark font-weight-bolder"
            >
              - {{ $t(`flight.${location}`) }}
            </p>
          </div>
          <div
            v-else
            class="mb-25 text-dark font-weight-bolder"
          >
            - Ghế còn trống
          </div>
        </div>

        <div class="text-left border-top mt-1">
          <h6 class="py-25 mb-0 text-right text-dark font-weight-bolder">
            {{ $t('flight.Price') }}:
            {{ isEmpty(seat.fares) ? 0 : `${formatCurrency(seat.fares[0].total)}` }}
          </h6>
        </div>
      </div>
    </b-tooltip>
  </BButton>
</template>

<script>
import {
  BButton,
  BTooltip,
  VBTooltip,
} from 'bootstrap-vue'
import { isEmpty } from 'lodash'
import Ripple from 'vue-ripple-directive'
import { toRefs } from '@vue/composition-api'

import { resolveLocationBySeat } from '@/constants/selectOptions'

import { formatCurrency } from '@core/utils/filter'

// FIXME dùng với modify/ancillary-services
export default {
  components: {
    BButton,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    seat: {
      type: Object,
      required: true,
    },
    seatAlreadyChosenArr: {
      type: Array,
      default: () => [],
    },
    dataToSelectSeat: {
      type: Array,
      default: () => [],
    },
    source: {
      type: String,
      default: () => '',
    },
  },
  setup(props) {
    const { seatAlreadyChosenArr, dataToSelectSeat } = toRefs(props)

    function findChosenSeat(seat) {
      if (!isEmpty(seatAlreadyChosenArr.value)) {
        const isSeatAlreadyChosen = seatAlreadyChosenArr.value.find(
          item => String(item.col) === String(seat.col)
            && String(item.row) === String(seat.row),
        )
        if (isSeatAlreadyChosen) {
          return true
        }
      }
      return false
    }

    function setVariantSeat(seat) {
      let variant = 'relief-info'
      switch (true) {
        case !seat.isAvailable:
          variant = 'secondary'
          break
        case findChosenSeat(seat):
          variant = 'success'
          break
        case !isEmpty(dataToSelectSeat.value)
          && !isEmpty(dataToSelectSeat.value.filter(item => item.hasAddedSeat)):
          if (
            dataToSelectSeat.value.find(
              item => item.seatSelected
                && String(item.seatSelected.row) === String(seat.row)
                && String(item.seatSelected.col) === String(seat.col),
            )
          ) {
            variant = 'relief-success'
          }
          break
        default:
          variant = 'relief-info'
      }
      return variant
    }

    function setDisabledSeat(seat) {
      let setDisabled = false
      if (!seat.isAvailable || seat.location === 'NoSeatAtThisLocation') {
        setDisabled = true
      }
      if (findChosenSeat(seat)) {
        setDisabled = true
      }
      return setDisabled
    }

    function setClassSeat(seat) {
      const seatClass = []
      if (Number(seat.col) === 0 || seat.location === 'NoSeatAtThisLocation') {
        seatClass.push('d-none')
      }
      if (!seat.isAvailable || findChosenSeat(seat)) {
        seatClass.push('cursor-not-allowed')
      }
      if (resolveLocationBySeat(seat).includes('ExitRowSeat')) {
        seatClass.push('border-top-danger-4')
      }
      return seatClass.join(' ')
    }

    return {
      isEmpty,
      setVariantSeat,
      setDisabledSeat,
      setClassSeat,
      resolveLocationBySeat,
      formatCurrency,
    }
  },
}
</script>
