var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BButton', {
    staticClass: "btn-icon my-50 p-0",
    class: _vm.setClassSeat(_vm.seat),
    style: ['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? "width:25px;height:30px" : "height:40px; width:40px;",
    attrs: {
      "id": "seat-".concat(_vm.seat.row, "-").concat(_vm.seat.col),
      "variant": _vm.setVariantSeat(_vm.seat),
      "disabled": _vm.setDisabledSeat(_vm.seat)
    },
    on: {
      "click": function click() {
        return _vm.$emit('handleClickSeat', _vm.seat);
      }
    }
  }, [_c('span', {
    class: "text-nowrap ".concat(['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? ' font-small-1' : ' font-small-3')
  }, [_vm._v(" " + _vm._s(_vm.seat.row) + _vm._s(_vm.seat.col) + " ")]), _c('b-tooltip', {
    attrs: {
      "id": "tooltip-".concat(_vm.seat.row, "-").concat(_vm.seat.col),
      "target": "seat-".concat(_vm.seat.row, "-").concat(_vm.seat.col),
      "triggers": "hover",
      "variant": "warning",
      "placement": "top",
      "boundary": "window",
      "disabled": !_vm.seat.isAvailable
    }
  }, [_c('div', {
    staticClass: "px-1 py-50"
  }, [_c('h5', {
    staticClass: "text-white pb-25 d-flex justify-content-between border-bottom text-nowrap"
  }, [_c('span', {
    staticClass: "mr-2 text-dark"
  }, [_vm._v(_vm._s(_vm.$t('flight.seatNumber')) + " " + _vm._s(_vm.seat.row) + _vm._s(_vm.seat.col))])]), _c('div', {
    staticClass: "text-left text-dark"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.seatDescription')) + ": "), !_vm.isEmpty(_vm.resolveLocationBySeat(_vm.seat, _vm.source)) ? _c('div', _vm._l(_vm.resolveLocationBySeat(_vm.seat, _vm.source), function (location, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-25 text-dark font-weight-bolder"
    }, [_vm._v(" - " + _vm._s(_vm.$t("flight.".concat(location))) + " ")]);
  }), 0) : _c('div', {
    staticClass: "mb-25 text-dark font-weight-bolder"
  }, [_vm._v(" - Ghế còn trống ")])]), _c('div', {
    staticClass: "text-left border-top mt-1"
  }, [_c('h6', {
    staticClass: "py-25 mb-0 text-right text-dark font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.Price')) + ": " + _vm._s(_vm.isEmpty(_vm.seat.fares) ? 0 : "".concat(_vm.formatCurrency(_vm.seat.fares[0].total))) + " ")])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }